.notification {
  border-radius: 4px;
  display: grid;
  grid-gap: 10px;
  grid-column: 1 / -1;
  grid-row: 1/ -1;
  grid-template-columns: 1fr 5fr;
  /* border: 1px solid sienna; */
  position: relative;
  transition: 0.35s;
  transform: translateY(100%);
}

.notification {
  transform: translate(0, 0);
}

.notification.success {
  background-color: teal;
}

.notification.warning {
  background-color: orange;
}

.notification.danger {
  background-color: tomato;
}

.notification img {
  justify-self: center;
  align-self: center;
  width: auto;
}

.notification {
  overflow: auto;
  color: whitesmoke;
  font-weight: 500;
  align-self: center;
  padding-right: 10px;
}

.notification:hover {
  border-color: black;
}
