.paracordBracelets{

    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    /* justify-content: space-around; */
    margin: 15%;
    /* justify-content: space-between; */
    
}
.Bracelet{
width: 200px;
height: 200px;
    color: red;
    background: white;
    border: 2px solid red;
    margin: 5%;
    font-family: fantasy;
    background-color: rgba(0, 0, 0, 0.3);

}

.Paracord {
    text-align: center;
  }
  .Header {
    color: red;
    text-shadow: 1px 1px 5px #fff;
    background: whitesmoke;
    background-color: rgba(0, 0, 0, 0.3);
    width: 200px;
    height: 50px;
    border: red 2px solid;
    margin-left: 40%;
    margin-top: 20%;
    font-style: oblique ;
    font-family: fantasy;
  
  }