body {
	margin: 0;
	font-family: -apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* background-color: black; */

	background: url(/static/media/pinkA.1c1e7a28.avif);
	@media only screen and (max-device-width: 375px) {
		background-size: 100%
			100%;
	}

	@media only screen and (max-device-width: 470px) {
		background-size: 100%
			100%;
	}

	@media only screen and (max-device-width: 593px) {
		background-size: 100%
			100%;
	}
	@media only screen and (max-device-width: 686px) {
		background-size: 100%
			100%;
		background-color: rgb(
			72,
			159,
			97
		);
	}

	@media only screen and (max-device-width: 795px) {
		background-size: 100%
			100%;
	}

	@media only screen and (max-device-width: 810px) {
		background-size: 100%
			100%;
	}
	background-color: rgb(
		72,
		159,
		97
	);
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
}

code {
	font-family: source-code-pro,
		Menlo,
		Monaco,
		Consolas,
		"Courier New",
		monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.Home {
	text-align: center;
	margin-top: 20%;
}
.Contact {
	text-align: center;
	margin-top: 10%;
}

/* .App{
  background-color:rgb(228, 80, 22);
  padding-top: 5%;
  padding-bottom: 10%;
} */

/* .dialog-enter {
  opacity: 0.01;
  transform: scale(1.1);
}

.dialog-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: all 300ms;
}

.dialog-exit {
  opacity: 1;
  transform: scale(1);
}

.dialog-exit-active {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 300ms;
}  */

/* .overlay-appear {
  opacity: 0.01;
}

.overlay-appear.overlay-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
} */
#button {
	position: absolute;

	z-index: 3;
	cursor: pointer;
}

/* #overlay {
  @media (max-width: 494px) {
    margin-top: 30%;
  }
} */
#overlay {
	position: fixed;
	display: visible;
	background-size: center;
	/* Full width (cover the whole page) */
	/* Full height (cover the whole page) */
	margin-top: 6%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(
		0,
		0,
		0,
		0.5
	); /* Black background with opacity */
	z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
	/* cursor: pointer; Add a pointer on hover */
}

body {
	background-color: rgb(
		246,
		21,
		201
	);
}
.App
	.Name {
	background-color: rgb(
		246,
		21,
		201
	);
}
.Name {
	@media only screen and (max-device-width: 354px) {
		font-size: 3px;
		background-color: rgb(
			246,
			21,
			201
		);
	}
}
.Home {

@media (max-device-width: 375px) {
		
		.CardOne {
			margin-top: 10%;
			position: scroll;
			width: 100%;
		}
		.CardTwo {
			width: 100px;
		}
	}
	@media only screen and (max-device-width: 494px) {
		.Jewelry {
			flex-direction: column;
			margin: 20%;
			justify-content: center;
			align-items: center;
			margin: 20%;
		}
		.Jewelry

		.CardOne {
			margin-top: 10%;
			position: scroll;
			width: 100%;
		}
		.CardTwo {
			width: 100px;
		}
	}

	@media only screen and (max-device-width: 569px) {
		.Jewelry {
			flex-direction: column;
			
			justify-content: center;
			align-items: center;
			margin: 20%;
		}

		.CardOne {
			/* margin-top: 20%; */
			width: 100%;
		}
		.CardTwo {
			width: 100px;
		}

		@media only screen and (max-device-width: 600px) {
		.Jewelry {
			
			flex-direction: column;
			justify-content: center;
			margin: 20%;
		}}
	}

	@media only screen and (max-device-width: 414px) {
		.CardOne {
			/* margin-top: 30%; */
			position: scroll;
			width: 100%;
		}
		.CardTwo {
			width: 100px;
		}
	}

	@media only screen and (max-device-width: 767px) {
		.CardOne {
			/* margin-top: 50%; */
			width: 100%;
		}
		.CardTwo {
			width: 100%;
		}
		.CardTwoText {
			flex-wrap: flex;
			margin-bottom: 20%;
		}
		.dialog {
			margin-top: 10%;
		}
	}
}

@media only screen and (max-device-width: 375px) {
	.Jewelry {
		margin: 20;
	}
	#overlay {
		position: scroll;
		display: visible;
		background-size: center;
		/* Full width (cover the whole page) */
		/* Full height (cover the whole page) */
		margin-top: 10%;
		margin-bottom: 30%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		/* background-color: rgba(0, 0, 0, 0.5); Black background with opacity */
		z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
		/* cursor: pointer; Add a pointer on hover */
	}
	.dialog--overlay
		.dialog {
		margin-top: 10%;
	}
}

@media only screen and (max-device-width: 375px) {
	#Candido {
		height: 100%;
	}
}

@media only screen and (max-device-width: 375px) {
	.dialog--overlay
		.dialog {
		margin-top: 10%;
	}
	.CardTwo {
		position: scroll;
	}
	.CardTwoText {
		position: scroll;
		margin-bottom: 20%;
	}

	#CandidoText {
		font-size: 12px;
	}
}

@media only screen and (max-device-width: 569px) {
	.dialog--overlay
		.dialog {
		margin-top: 20%;
	}

	#Candido {
		height: 100%;
	}
}
@media only screen and (max-device-width: 973px) {
	.dialog--overlay
		.dialog {
		margin-top: 20%;
	}

	#Candido {
		height: 100%;
	}
}

@media only screen and (max-device-width: 976px) {
	#Candido {
		height: 100%;
	}
}
@media only screen and (max-device-width: 604px) {
	.dialog--overlay
		.dialog {
		margin-top: 50%;
	}

	#Candido {
		height: 100%;
	}
}

/* 
.home_text {
  @media only screen and (max-device-width: 375px) {
    margin: 30%;
    margin-top: 10%;
    margin-left: 20%;
  }

  @media only screen and (max-device-width: 470px) {
    margin: 10%;
    margin-top: 10%;
    margin-left: 20%;
  }

  @media only screen and (max-device-width: 767px) {
    margin: 10%;
    margin-top: 10%;
    margin-left: 20%;
    justify-content: space-evenly;
  }
} */
/* .overlay {

    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    
    } */

/* #Home{


      position: absolute; 
      height: 250px; 
      width: 500px; 
      
    } */

.subscribe {
  grid-area: subscribe;
  width: 400px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: [input] 4fr [button] 1fr;
  margin-left: 30%;
}

.subscribe-email {
  grid-area: input;
  border-radius: 4px;
  height: 40px;
  background-color: black;
  border: 1px solid whitesmoke;
  color: whitesmoke;
  padding-left: 10px;
  outline: none;
  font-size: 16px;
  transition: all 0.2s ease-in;
}

.subscribe-email::-webkit-input-placeholder {
  color: whitesmoke;
  font-size: 16px;
}

.subscribe-email::placeholder {
  color: whitesmoke;
  font-size: 16px;
}

.subscribe-email:focus,
:hover {
  border-color: teal;
}

.subscribe-button {
  grid-area: button;
  height: 40px;
  background-color: aqua;
  border: 1px solid black;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  font-weight: bolder;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.subscribe-button:hover {
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
}

.subscribe-button:focus {
  outline: none;
}

@media (max-width: 700px) {
  .subscribe {
    grid-template-areas:
      "input"
      "button";
  }
  .subscribe-email,
  .subscribe-button {
    width: 250px;

    grid-column: 1 / -1;
  }
}

.notification {
  border-radius: 4px;
  display: grid;
  grid-gap: 10px;
  grid-column: 1 / -1;
  grid-row: 1/ -1;
  grid-template-columns: 1fr 5fr;
  /* border: 1px solid sienna; */
  position: relative;
  transition: 0.35s;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.notification {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.notification.success {
  background-color: teal;
}

.notification.warning {
  background-color: orange;
}

.notification.danger {
  background-color: tomato;
}

.notification img {
  justify-self: center;
  align-self: center;
  width: auto;
}

.notification {
  overflow: auto;
  color: whitesmoke;
  font-weight: 500;
  align-self: center;
  padding-right: 10px;
}

.notification:hover {
  border-color: black;
}

.Candido {
  @media (min-width: 375px) {
    width: 300px;
    height: 400px;
  }
}

.paracordBracelets{

    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
    /* justify-content: space-around; */
    margin: 15%;
    /* justify-content: space-between; */
    
}
.Bracelet{
width: 200px;
height: 200px;
    color: red;
    background: white;
    border: 2px solid red;
    margin: 5%;
    font-family: fantasy;
    background-color: rgba(0, 0, 0, 0.3);

}

.Paracord {
    text-align: center;
  }
  .Header {
    color: red;
    text-shadow: 1px 1px 5px #fff;
    background: whitesmoke;
    background-color: rgba(0, 0, 0, 0.3);
    width: 200px;
    height: 50px;
    border: red 2px solid;
    margin-left: 40%;
    margin-top: 20%;
    font-style: oblique ;
    font-family: fantasy;
  
  }
