body {
	margin: 0;
	font-family: -apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* background-color: black; */

	background: url(pinkA.avif);
	@media only screen and (max-device-width: 375px) {
		background-size: 100%
			100%;
	}

	@media only screen and (max-device-width: 470px) {
		background-size: 100%
			100%;
	}

	@media only screen and (max-device-width: 593px) {
		background-size: 100%
			100%;
	}
	@media only screen and (max-device-width: 686px) {
		background-size: 100%
			100%;
		background-color: rgb(
			72,
			159,
			97
		);
	}

	@media only screen and (max-device-width: 795px) {
		background-size: 100%
			100%;
	}

	@media only screen and (max-device-width: 810px) {
		background-size: 100%
			100%;
	}
	background-color: rgb(
		72,
		159,
		97
	);
	background-size: cover;
	background-attachment: fixed;
	background-repeat: no-repeat;
}

code {
	font-family: source-code-pro,
		Menlo,
		Monaco,
		Consolas,
		"Courier New",
		monospace;
}
